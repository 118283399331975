interface APIRequest {
  address: object;
  phone: string;
  email: string;
  dwelling: Number;
  dwellingExtension: Number;
  liability: Number;
  contentsCoverage: Number;
  otherStructure: Number;
  deductable: Number;
  policyStartDate: string;
  homeFunction: string;
  foundationType: string;
  roofShape: string;
  roofType: string;
  constructionType: string;
  exterior: string;
  roofLastModifiedYear: Number;
  electricalSystemLastModifiedYear: Number;
  plumbingSysteLastModifiedYear: Number;
  ACSystemLastModifiedYear: Number;
  people: object;
  environment: string;
  dontQuery: object;
  isCondo: boolean;
  squareFeet: Number;
  yearBuilt: Number;
}

interface APIResponse {
  json: any;
  quotes: object;
}

export async function makeRequestToMainApi(request: APIRequest): Promise<APIResponse> {
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(request as any),
    redirect: "follow",
    mode: "cors" as RequestMode,
  } as any;

  let value = await fetch(
    "http://petesproject-env.eba-mxrabq3t.us-east-1.elasticbeanstalk.com/getMainQuote",
    requestOptions
  );

  // api response
  return value as any;

  /*I will swap this out with a real API call eventually*/

  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(value as any);
  //   }, 1000);
  // });
}