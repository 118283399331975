import React from "react";
import { Link, useNavigate } from "react-router-dom";
import btnarrow from "../images/btnarrow.png";
import info from "../images/info.png";
import dwellburn from "../images/dwellburn.jpg";
import dwellburn2 from "../images/dwellburn2.jpg";
import step10bg from "../images/step10bg.jpeg";

import { ChangeEvent, FormEvent, useState } from "react";
import { stat } from "fs";

function StepTen() {
  const navigate = useNavigate();
  // const submitForm = () => {
  //   navigate("/stepEleven");
  // };

  const [dwellingExtension, setDwellingExtension] = useState("");
  const [liability, setliability] = useState("");
  const [deductible, setdeductible] = useState("");
  const [contentsCoverage, setContentsCoverage] = useState("");

  const [state, setState] = useState({
    contents: "0",
    dwelling: "0",
    otherStructure: "0",

    deductible: "",
    liability: "",
    dwellingExtension: "",
    contentsCoverage: "",
  });

  const onFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value: typeof state[keyof typeof state] = event.target.value;
    if (event.target.name == "dwellingExtension") {
      setDwellingExtension("");
    }
    if (event.target.name == "liability") {
      setliability("");
    }
    if (event.target.name == "deductible") {
      setdeductible("");
    }
    if (event.target.name == "contentsCoverage") {
      setContentsCoverage("");
    }
    setState({ ...state, [event.target.name]: value });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (state.dwellingExtension === "") {
      setDwellingExtension("Dwelling Extension - Choose any one");
    } else if (state.liability === "") {
      setliability("Liability - Choose any one");
    } else if (state.deductible === "") {
      setdeductible("Deductible - Choose any one");
    } else if (state.contentsCoverage === "") {
      setContentsCoverage("Other Coverages - Choose any one");
    } else {
      localStorage.setItem("step_10", JSON.stringify(state));
      navigate("/stepEleven");
    }
  };

  function setDwelling(val: any) {
    state.dwelling = val;
  }

  function setOtherStructure(val: any) {
    state.otherStructure = val;
  }
  function setContents(val: any) {
    state.contents = val;
  }
  return (
    <div>
      <Link className="back-btn" to="/stepNine">
        <i className="fa fa-angle-left"></i>
      </Link>
      <section className="page10 header">
        <form className="container-fluid" onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-10 coverage-box">
              <div className="coverage-head">
                <h2>Coverage Profile</h2>
                <p>Hover over each coverage to see what each coverage is. </p>
              </div>
              <div className="row d-block">
                <div className="col">
                  <div className="dwelling">
                    <label>
                      {" "}
                      <span> Dwelling </span>
                      <img src={info} alt="" />
                      ......................................................................
                    </label>
                    <div className="inputDollerBox">
                      <span id="doller_sign">$</span>
                      <input
                        className="text-field"
                        type="number"
                        required
                        onChange={(e) => {
                          setDwelling(e.target.value);
                        }}
                        placeholder="600,000"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md mt-3">
                  <div className="dwelling-extension d-flex">
                    <p>
                      {" "}
                      <span>Dwelling Extension </span>
                      <img src={info} alt="" />
                    </p>
                    <div className="wrapper">
                      <input
                        type="radio"
                        name="dwellingExtension"
                        onChange={onFieldChange}
                        value="25%"
                        // onChange={handleChange}
                        id="option-1"
                      />
                      <input
                        type="radio"
                        name="dwellingExtension"
                        value="50%"
                        onChange={onFieldChange}
                        // onChange={handleChange}
                        id="option-2"
                      />
                      <label htmlFor="option-1" className="option option-1">
                        <div className="dot"></div>
                        <span>25%</span>
                      </label>
                      <label htmlFor="option-2" className="option option-2">
                        <div className="dot"></div>
                        <span>50%</span>
                      </label>
                    </div>
                  </div>
                  <p className="error_msg_10">{dwellingExtension}</p>
                </div>
                <div className="col">
                  <div className="liability d-flex">
                    <p>
                      {" "}
                      <span>Liability</span>
                      <img src={info} alt="" />
                    </p>
                    <div className="liability-wrapper">
                      <input
                        type="radio"
                        name="liability"
                        value="100000"
                        id="liability-1"
                        onChange={onFieldChange}
                      />
                      <input
                        type="radio"
                        name="liability"
                        id="liability-2"
                        value="300000"
                        onChange={onFieldChange}
                      />
                      <input
                        type="radio"
                        name="liability"
                        value="500000"
                        id="liability-3"
                        onChange={onFieldChange}
                      />
                      <label htmlFor="liability-1" className="option option-1">
                        <div className="dot"></div>
                        <span>100,000</span>
                      </label>
                      <label htmlFor="liability-2" className="option option-2">
                        <div className="dot"></div>
                        <span>300,000</span>
                      </label>
                      <label htmlFor="liability-3" className="option option-3">
                        <div className="dot"></div>
                        <span>500,000</span>
                      </label>
                    </div>
                  </div>
                  <p className="error_msg_10">{liability}</p>
                </div>
                <div className="col-md mt-3">
                  <div className="contents">
                    <label>
                      {" "}
                      <span> contents </span>
                      <img src={info} alt="" />
                      .........................................................................
                    </label>
                    <div className="inputDollerBox">
                      <span id="doller_sign">$</span>
                      <input
                        className="text-field"
                        type="number"
                        required
                        onChange={(e) => {
                          setContents(e.target.value);
                        }}
                        placeholder="600,000"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md mt-4">
                  <div className="structure">
                    <label>
                      {" "}
                      <span> Other Structures </span>
                      <img src={info} alt="" />
                      .......................................................
                    </label>
                    <div className="inputDollerBox">
                      <span id="doller_sign">$</span>
                      <input
                        className="text-field"
                        type="text"
                        onChange={(e) => {
                          setOtherStructure(e.target.value);
                        }}
                        required
                        placeholder="20,000"
                      />
                    </div>
                  </div>
                </div>
                <div className="col mt-3">
                  <div className="deductible d-flex">
                    <p>
                      {" "}
                      <span>Deductible</span>
                      <img src={info} alt="" />
                    </p>
                    <div className="deductible-wrapper">
                      <input
                        type="radio"
                        name="deductible"
                        value="1000"
                        id="deductible-1"
                        onChange={onFieldChange}
                      />
                      <input
                        type="radio"
                        name="deductible"
                        value="2500"
                        id="deductible-2"
                        onChange={onFieldChange}
                      />
                      <input
                        type="radio"
                        name="deductible"
                        value="5000"
                        id="deductible-3"
                        onChange={onFieldChange}
                      />
                      <label htmlFor="deductible-1" className="option option-1">
                        <div className="dot"></div>
                        <span>1,000</span>
                      </label>
                      <label htmlFor="deductible-2" className="option option-2">
                        <div className="dot"></div>
                        <span>2,500</span>
                      </label>
                      <label htmlFor="deductible-3" className="option option-3">
                        <div className="dot"></div>
                        <span>5,000</span>
                      </label>
                    </div>
                  </div>
                  <p className="error_msg_10">{deductible}</p>
                </div>
                <div className="col-md">
                  <div className="coverages ">
                    <p>
                      {" "}
                      <span>Other Coverages:</span>
                      <img src={info} alt="" />
                    </p>
                    <div className="row">
                      <div className="col">
                        <input
                          type="radio"
                          name="contentsCoverage"
                          value="Sewer Line"
                          onChange={onFieldChange}
                          id="coverages-1"
                        />
                        <input
                          type="radio"
                          name="contentsCoverage"
                          value="Sewer Line"
                          onChange={onFieldChange}
                          id="coverages-2"
                        />
                        <input
                          type="radio"
                          name="contentsCoverage"
                          value="Sewer Line"
                          onChange={onFieldChange}
                          id="coverages-3"
                        />
                        <input
                          type="radio"
                          name="contentsCoverage"
                          value="Equip Breakdown"
                          onChange={onFieldChange}
                          id="coverages-4"
                        />
                        <input
                          type="radio"
                          name="contentsCoverage"
                          value="Sewer Line"
                          onChange={onFieldChange}
                          id="coverages-5"
                        />
                        <label
                          htmlFor="coverages-1"
                          className="option option-1"
                        >
                          <div className="dot"></div>
                          <span>Sewer Line</span>
                        </label>
                        <label
                          htmlFor="coverages-2"
                          className="option option-2"
                        >
                          <div className="dot"></div>
                          <span>Sewer Line</span>
                        </label>
                        <label
                          htmlFor="coverages-3"
                          className="option option-3"
                        >
                          <div className="dot"></div>
                          <span>Sewer Line</span>
                        </label>
                        <label
                          htmlFor="coverages-4"
                          className="option option-4"
                        >
                          <div className="dot"></div>
                          <span>Equip Breakdown</span>
                        </label>
                        <label
                          htmlFor="coverages-5"
                          className="option option-5"
                        >
                          <div className="dot"></div>
                          <span>Sewer Line</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <p className="error_msg_10">{contentsCoverage}</p>
                </div>
              </div>
            </div>
            <div className="col text-center">
              <div className="dean-st">
                <p className="dean-st-heading">303 Dean St</p>
                <div className="circle">
                  <h5>$77</h5>
                  <hr className="solid" />
                  <span>Month</span>
                  <p>
                    <img src={info} alt="" />
                  </p>
                </div>
                {/* <button type="submit" className="btn ">
                  <span> Complete Your Quote </span>{" "}
                  <img src={btnarrow} alt="" />
                </button> */}
                {/* <Link to="/stepEleven" className="btn ">
                        <span> Complete Your Quote </span> <img src={btnarrow} alt=""/>
                        </Link> */}
              </div>
              <button type="submit" className="btn ">
                <span> Complete Your Quote </span> <img src={btnarrow} alt="" />
              </button>

              {/*<div className="dwelling-head">
                        <h4>Dwelling</h4>
                        <p>Dwelling coverage will be your limit to repair your home from losses such as fire, wind, hail
                            and other catastrophic claims. </p>
                    </div>
                     <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2" className="active" aria-current="true"></button>
                            
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item">
                                <img src={dwellburn} className="d-block w-50" alt="..."/>
                            </div>
                            <div className="carousel-item active">
                                <img src={dwellburn2} className="d-block w-50" alt="..."/>
                            </div>
                          
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span aria-hidden="true" style={{color:' #626262',fontSize:'40px'}}  ><i className="fa-solid fa-chevron-left"></i></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="" aria-hidden="true" style={{color:' #626262',fontSize:'40px'}}  ><i className="fa-solid fa-chevron-right"></i> </span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div> */}
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}

export default StepTen;
