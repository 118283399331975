import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import pg15img1 from "../images/pg15img1.png";
import pg15img2 from "../images/pg15img2.png";

function StepFifteen() {
  const [roofShape, setRoofShape] = useState("peaked");
  const navigate = useNavigate();

  const submitForm = () => {
    navigate("/stepSixteen");
    localStorage.setItem("roofShape", roofShape);
  };

  return (
    <div>
      <Link className="back-btn" to="/stepFourteen">
        <i className="fa fa-angle-left"></i>
      </Link>
      <section className="page15">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 text-center">
              <h4>What shape roof do you have?</h4>
              <div className="radio-buttons">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="radio"
                    value="peaked"
                    onChange={(e) => {
                      setRoofShape(e.target.value);
                    }}
                    checked
                  />
                  <span className="radio-btn">
                    <i className="las la-check"></i>
                    <div className="property-img">
                      <img src={pg15img1} alt="" />
                      <h3>Peaked</h3>
                    </div>
                  </span>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    value="flat"
                    onChange={(e) => {
                      setRoofShape(e.target.value);
                    }}
                    name="radio"
                  />
                  <span className="radio-btn">
                    <i className="las la-check"></i>
                    <div className="property-img">
                      <img src={pg15img2} alt="" />
                      <h3>Flat</h3>
                    </div>
                  </span>
                </label>

                <div className="button">
                  <a
                    onClick={submitForm}
                    id="btn2"
                    className="submit"
                    type="submit"
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepFifteen;
