import React, { useState } from "react";
import { Link } from "react-router-dom";
import btnarrow from "../images/btnarrow.png";
import Formlelement from "./step2helper/Formlelement";
import { useNavigate } from "react-router-dom";

function StepTwo() {
  const emptyData = { 'firstName': '', 'lastName': '', 'dateOfBirth': '',"gender":"M" }
  const [formData, setformData] = useState([emptyData])
  const navigate = useNavigate();

  const handelsubmit = (event:any) => {
    event.preventDefault();
    // if(formData.includes(emptyData)){
    //   alert('there is empty data')
    // }else{

    //   navigate("/stepThree");
    // }
    navigate("/stepThree");
  }
  return (
    <div>
      <section className="page2 ">
        <Link className="back-btn" to="/"><i className="fa fa-angle-left"></i></Link>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-10">
              <h1>Create household:</h1>

              {/* method="get" action="/stepThree" */}
              <form className="steptwoform" method="POST" onSubmit={handelsubmit}>
                {
                  formData.map((data, i) => <Formlelement isLast={i === formData.length - 1} data={data} formData={formData} setformData={setformData} dataNumber={i} emptyData={emptyData} key={i} />)

                }
                <div className="row justify-content-center">
                  <div>
                    <div className="tacbox">
                      <input id="checkbox" required type="checkbox" />
                      <label htmlFor="checkbox">

                        This application will run real home insurance qoutes based
                        on your information. Please check hre that you agree to &nbsp;
                        <a href="#" className="tandc">
                          terms and conditions
                        </a>
                        .
                        <a href="#" className="text-dark">

                          <span> skip for now</span>
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <div className="form-btn">
                      <button type="submit" name="submit" className="btn btn-outline-dark">
                        Run Quotes <img src={btnarrow} alt="" />
                      </button>
                    </div>
                  </div>
                </div>


              </form>


            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepTwo;
