import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import pg14img1 from "../images/pg14img1.png";
import pg14img2 from "../images/pg14img2.png";
import pg14img3 from "../images/pg14img3.png";

function StepFourteen() {
  const [foundationType, setFoundationType] = useState("slab");
  const navigate = useNavigate();

  const submitForm = () => {
    navigate("/stepFifteen");
    localStorage.setItem("foundationType", foundationType);
  };

  return (
    <div>
      <Link className="back-btn" to="/stepTwelve">
        <i className="fa fa-angle-left"></i>
      </Link>
      <section className="page14 header">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <h4>What type of foundation does your property have?</h4>
              <div className="radio-buttons text-center">
                <label className="custom property">
                  <input
                    type="radio"
                    name="radio"
                    value="slab"
                    defaultChecked
                    onChange={(e) => {
                      setFoundationType(e.target.value);
                    }}
                  />
                  <span className="radio-btn">
                    <div className="custom-1">
                      <img src={pg14img1} alt="" />
                    </div>
                  </span>
                </label>
                <label className=" property">
                  <input
                    type="radio"
                    name="radio"
                    value="crawl"
                    onChange={(e) => {
                      setFoundationType(e.target.value);
                    }}
                  />
                  <span className="radio-btn">
                    <div className="custom-2">
                      <img src={pg14img2} alt="" />
                    </div>
                  </span>
                </label>
                <label className=" property">
                  <input
                    type="radio"
                    name="radio"
                    value="basement"
                    onChange={(e) => {
                      setFoundationType(e.target.value);
                    }}
                  />
                  <span className="radio-btn">
                    <div className="custom-3">
                      <img src={pg14img3} alt="" />
                    </div>
                  </span>
                </label>

                <div className="button">
                  {/* <Link to="/stepFifteen" id="btn2">
                    {" "}
                    Continue{" "}
                  </Link> */}
                  <a
                    onClick={submitForm}
                    id="btn2"
                    className="submit"
                    type="submit"
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepFourteen;
