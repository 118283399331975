import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function StepFour() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      console.log("Step four.....");
      navigate("/stepFive");
    }, 8000);
  }, []);

  const [lat, setlat] = useState(localStorage.getItem("lat"));
  const [lng, setlng] = useState(localStorage.getItem("lng"));

  const iframeData = `https://maps.google.com/maps?q=${lat},${lng}&hl=es;&output=embed`;
  console.log("lat: " + lat);
  console.log("lng: " + lng);

  return (
    <div>
      <Link className="back-btn" to="/stepThree">
        <i className="fa fa-angle-left"></i>
      </Link>
      <section className="page4 header">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <h3>
                {" "}
                <span> running quotes</span>
                <i className="fa fa-rotate-right"></i>
              </h3>
              <div className="map">
                <iframe
                  className="lmap"
                  // src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  src={iframeData}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepFour;
