import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import speechBubble from "../images/speech-bubble.png";
import btnarrow from "../images/btnarrow.png";

function StepThree() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const formhandler = (event: any) => {
    event.preventDefault();
    localStorage.setItem("email", email);
    localStorage.setItem("phone", phone);
    navigate("/stepFour");
  };

  function onlyNumberKey(evt: any) {
    // Only ASCII character in that range allowed
    var ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  }
  return (
    <div>
      <Link className="back-btn" to="/stepTwo">
        <i className="fa fa-angle-left"></i>
      </Link>
      <section className="page3 header">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <h4>How can we send you your quotes?</h4>
              <div className="row justify-content-center">
                <form
                  onSubmit={formhandler}
                  className="row d-block justify-content-center form-items"
                >
                  <div className="col-md-6 mt-3">
                    <div className="phone mt-3">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={speechBubble} alt="" />
                      </span>

                      <input
                        className=""
                        type="number"
                        name="phone"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                        min="0"
                        placeholder="Phone"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mail mt-3">
                      <span> @</span>
                      <input
                        className="email"
                        type="email"
                        name="email"
                        id=""
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="btn1 text-center">
                      <button className="btn btn-outline-dark" type="submit">
                        Run Quotes <img src={btnarrow} alt="" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepThree;
