import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

function StepNinteen() {
  const navigate = useNavigate();

  const options = [];
  for (let i = 1970; i <= 2023; i++) {
    options.push({ value: `${i}`, label: `${i}` });
  }

  const [roofLastModifiedYear, setRoofLastModifiedYear] = useState("1970");
  const [electricalSystemLastModifiedYear,setElectricalSystemLastModifiedYear,] = useState("1970");
  const [plumbingSysteLastModifiedYear, setPlumbingSysteLastModifiedYear] = useState("1970");
  const [ACSystemLastModifiedYear, setACSystemLastModifiedYear] = useState("1970");

  const RoofLastModifiedYear = (e: any) => {
    setRoofLastModifiedYear(e.value);;
  };
  const ElectricalSystemLastModifiedYear = (e: any) => {
    setElectricalSystemLastModifiedYear(e.value);;
  };
  const PlumbingSysteLastModifiedYear = (e: any) => {
    setPlumbingSysteLastModifiedYear(e.value);;
  };
  const acsystemLastModifiedYear = (e: any) => {
    setACSystemLastModifiedYear(e.value);;
  };
  const submitForm = () => {
    navigate("/stepTwenty");
    localStorage.setItem("roofLastModifiedYear", roofLastModifiedYear);
    localStorage.setItem("electricalSystemLastModifiedYear",electricalSystemLastModifiedYear);
    localStorage.setItem("plumbingSysteLastModifiedYear",plumbingSysteLastModifiedYear);
    localStorage.setItem("ACSystemLastModifiedYear", ACSystemLastModifiedYear);
  };
  return (
    <div>
      <Link className="back-btn" to="/stepEighteen">
        <i className="fa fa-angle-left"></i>
      </Link>
      <section className="page19">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-8 col-md-8 col-lg-7 col-xl-6">
              <h4>
                What year (if any) have the following systems been updated?{" "}
              </h4>
              <form onSubmit={submitForm}>
                <div className="row justify-content-center mt-4">
                  <div className="col-md-7 mt-4">
                    <div className="selection_box">
                      <label htmlFor="roof">Roof</label>
                      <div className="select_box">
                        <Select
                          id=""
                          name="roof"
                          className="roof"
                          options={options}
                          onChange={RoofLastModifiedYear} // assign onChange function
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 mt-3">
                    <div className="selection_box">
                      <label htmlFor="eletrical">Eletrical System</label>
                      <div className="select_box">
                        <Select
                          id=""
                          name="eletrical"
                          className="roof"
                          onChange={ElectricalSystemLastModifiedYear}
                          options={options}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 mt-3">
                    <div className="selection_box">
                      <label htmlFor="eletrical">Plumbing System</label>
                      <div className="select_box">
                        <Select
                          id=""
                          name="plumbing"
                          className="roof"
                          onChange={PlumbingSysteLastModifiedYear}
                          options={options}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 mt-3">
                    <div className="selection_box">
                      <label htmlFor="eletrical">A/C System</label>
                      <div className="select_box">
                        <Select
                          id=""
                          name="ac-system"
                          onChange={acsystemLastModifiedYear}
                          className="roof"
                          options={options}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-4 mt-5 text-center">
                      <div className="button text-center ">
                        <button type="submit"> Continue </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepNinteen;
