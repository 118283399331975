import { useState, useEffect } from "react";
import AutoComplete from "./AutoComplete";

function StepOne() {
  return (
    <>
      <AutoComplete />
    </>
  );
}
export default StepOne;
