import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import woodframeimg from "../images/woodframeimg.png";
import solidbrickimg from "../images/solidbrickimg.png";
import concreteimg from "../images/concreteimg.png";
function StepSeventeen() {
  const [constructionType, setConstructionType] = useState("Wood");
  const navigate = useNavigate();

  const submitForm = () => {
    navigate("/stepEighteen");
    localStorage.setItem("constructionType", constructionType);
  };
  return (
    <div>
      <Link className="back-btn" to="/stepSixteen">
        <i className="fa fa-angle-left"></i>
      </Link>
      <section className="page17 header">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h4>What construction type is your home?</h4>
              <div className="radio-buttons text-center">
                <label className="custom-radio">
                  <input
                    type="radio"
                    value="Wood"
                    onChange={(e) => {
                      setConstructionType(e.target.value);
                    }}
                    name="radio"
                    defaultChecked
                  />
                  <span className="radio-btn">
                    {/* <i class="las la-check"></i> */}
                    <div className="construction-img">
                      <h3>Wood Frame</h3>
                      <img src={woodframeimg} alt="" />
                    </div>
                  </span>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    value="Solid brick or stone"
                    onChange={(e) => {
                      setConstructionType(e.target.value);
                    }}
                    name="radio"
                  />
                  <span className="radio-btn">
                    {/* <i class="las la-check"></i> */}
                    <div className="construction-img">
                      <h3>Solid brick or stone</h3>
                      <img src={solidbrickimg} alt="" />
                    </div>
                  </span>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    value="Concrete"
                    onChange={(e) => {
                      setConstructionType(e.target.value);
                    }}
                    name="radio"
                  />
                  <span className="radio-btn">
                    {/* <i class="las la-check"></i> */}
                    <div className="construction-img">
                      <h3>Concrete</h3>
                      <img src={concreteimg} alt="" />
                    </div>
                  </span>
                </label>
                <div className="button text-center">
                  <a
                    onClick={submitForm}
                    id="btn2"
                    className="submit"
                    type="submit"
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepSeventeen;
