import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

function Formlelement(props: any) {
  const increasecount = () => {
    props.setformData((data: any) => [...data, props.emptyData]);
  };
  const ref = useRef();

  const decreasecount = () => {
    props.setformData((data: any) =>
      data.filter(function (item: any, index: any) {
        return index !== props.dataNumber;
      })
    );
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageDate, seterrorMessageDate] = useState("");
  /*=======================================================================*/
  const updateperameter = (perameter: string, value: any, event: any) => {
    let temps = props.formData;
    temps[props.dataNumber][perameter] = value;
    props.setformData(temps);

    if (perameter == "dateOfBirth") {
      var check = isfutureDate(value);
      if (check) {
        event.target.value = "";
        temps[props.dataNumber][perameter] = "";
        seterrorMessageDate("Birthday date must be in past");
      }else{
        seterrorMessageDate("");
      }
    }
    localStorage.setItem("users_data", JSON.stringify(temps));
  };
  /*=======================================================================*/
  const onfocusType = (event: any) => {
    console.log(event.target.type);
    event.target.type = "date";
  };
  const onBlurType = (event: any) => {
    event.target.type = "date";
  };
  // const checkDateValid = (value: any) => {
  //   const checkfutureDate = isfutureDate(value);
  //   if (checkfutureDate) {
  //     // alert("Birthday date must be in past");
  //     seterrorMessageDate("Birthday date must be in past");
  //     // event.target.value = "";
  //   } else {
  //     seterrorMessageDate("");
  //   }
  // };
  /*=======================================================================*/
  function isfutureDate(value: any) {
    var now = new Date();
    var target = new Date(value);

    if (target.getFullYear() > now.getFullYear()) {
      return true;
    } else if (target.getFullYear() == now.getFullYear()) {
      if (target.getMonth() > now.getMonth()) {
        return true;
      } else if (target.getMonth() == now.getMonth()) {
        if (target.getDate() >= now.getDate()) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }
  return (
    <div className="steptwoformelements row">
      <div className="col-md-4">
        <input
          type="text"
          className="form-control"
          placeholder="First name"
          name="firstName"
          required={true}
          autoComplete="off"
          // value={props.formData[props.dataNumber]['firstName']}
          onChange={(e) => updateperameter("firstName", e.target.value, e)}
        />
      </div>
      <div className="col-md-4">
        <input
          type="text"
          name="lastName"
          className="form-control"
          placeholder="Last name"
          autoComplete="off"
          // value={props.formData[props.dataNumber]['lastName']}
          onChange={(e) => updateperameter("lastName", e.target.value, e)}
          required={true}
        />
      </div>
      <div className="col-md-3">
        <input
          type="text"
          className="form-date"
          name="dateOfBirth"
          id={"dateOfBirth_" + props.dataNumber}
          // value= {props.formData[props.dataNumber]['dateOfBirth']}
          onChange={(e) => updateperameter("dateOfBirth", e.target.value, e)}
          // onChange={(e) => checkDateValid(e)}
          onFocus={(e) => onfocusType(e)}
          onBlur={(e) => onBlurType(e)}
          placeholder="Date of Birth"
          required={true}
        />
        <span className="dob_valid_date_error">{errorMessageDate}</span>
      </div>

      {props.isLast ? (
        <div className="col-md-1">
          <a onClick={increasecount} href="#" className="plus-btn">
            +
          </a>
        </div>
      ) : (
        <div className="col-md-1">
          <a
            href="#"
            className="btn btn-danger minus_btn"
            onClick={decreasecount}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-dash-lg"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"
              ></path>
            </svg>
          </a>
        </div>
      )}
    </div>
  );
}

export default Formlelement;
