import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function StepEleven() {
  const [policyStartDate, setPolicyStartDate] = useState("1990");
  const navigate = useNavigate();
  const submitForm = () => {
    navigate("/stepTwelve");
    localStorage.setItem("policyStartDate", policyStartDate);
  };
  return (
    <div>
      <Link className="back-btn" to="/stepTen">
        <i className="fa fa-angle-left"></i>
      </Link>
      <section className="page11 header">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-md-8">
              <h4>When do you want your policy to start?</h4>
              <form
                onSubmit={submitForm}
                className="row justify-content-center"
              >
                <div className="col-md mt-5 text-center">
                  <input
                    type="date"
                    placeholder="date"
                    value={policyStartDate}
                    onChange={(e) => {
                      setPolicyStartDate(e.target.value);
                    }}
                    required
                  />
                  <hr />
                  <p>
                    if you're unsure of the start date, put the closest date and
                    we can change it any time
                  </p>
                </div>
                <div className="col-8-md mt-2">
                  <div className="policy-btn">
                    <Link to="" id="btn1">
                      {" "}
                      I don't know
                    </Link>
                    {/* <Link type="submit"  to="/stepTwelve" id="btn2">
                      {" "}
                      Continue{" "}
                    </Link> */}
                    <button id="btn2" type="submit">
                      Continue
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepEleven;
