import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import pg12img1 from "../images/pg12img1.png";
import pg12img2 from "../images/pg12img2.png";
import pg12img3 from "../images/pg12img3.png";

function StepTwelve() {
  const [homeFunction, setHomeFunction] = useState("primary");
  const navigate = useNavigate();

  const submitForm = () => {
    navigate("/stepFourteen");
    localStorage.setItem("homeFunction", homeFunction);
  };

  return (
    <div>
      <Link className="back-btn" to="/stepEleven">
        <i className="fa fa-angle-left"></i>
      </Link>
      <section className="page12 header">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <h4>What type of function does your property have?</h4>
              <form className="radio-buttons text-center">
                <label className="custom property">
                  <input
                    type="radio"
                    name="radio"
                    value="primary"
                    defaultChecked
                    onChange={(e) => {
                      setHomeFunction(e.target.value);
                    }}
                  />
                  <span className="radio-btn">
                    <div className="custom-1">
                      <img src={pg12img1} alt="" />
                      <h3>Primary Home</h3>
                    </div>
                  </span>
                </label>
                <label className=" property">
                  <input
                    type="radio"
                    name="radio"
                    value="second"
                    onChange={(e) => {
                      setHomeFunction(e.target.value);
                    }}
                  />
                  <span className="radio-btn">
                    <div className="custom-2">
                      <img src={pg12img2} alt="" />
                      <h3>Second Home</h3>
                    </div>
                  </span>
                </label>
                <label className=" property">
                  <input
                    type="radio"
                    name="radio"
                    value="rental"
                    onChange={(e) => {
                      setHomeFunction(e.target.value);
                    }}
                  />
                  <span className="radio-btn">
                    <div className="custom-3">
                      <img src={pg12img3} alt="" />
                      <h3>Rental Property</h3>
                    </div>
                  </span>
                </label>

                <div className="button">
                  {/* <Link to="/stepFourteen" id="btn2">
                    {" "}
                    Continue{" "}
                  </Link> */}
                  <a
                    onClick={submitForm}
                    id="btn2"
                    className="submit"
                    type="submit"
                  >
                    Continue
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepTwelve;
