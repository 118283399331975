import React from "react";
import { Link } from "react-router-dom";
import cactus from "../images/cactus.png";
import home from "../images/home (2).png";
import nationwide from "../images/nationwide.png";
import pg20travlimg from"../images/pg20travlimg.png";
import lemonade from "../images/lemonade.png";
import grange from "../images/grange logo.png";
import hippo from "../images/hippo.png";
import stillwater from "../images/stillwater.png";
import ethoslife from "../images/ethos_logo.png";
import branch from "../images/branch.svg";

import { useState, useEffect } from "react";
import { makeRequestToMainApi } from "./makeRequestToMainApi";
import { json } from "stream/consumers";

function StepTwenty() {
  const [list, setList] = useState([]);
  const [loaderClass, setloaderClass] = useState(true);

  const res_data = JSON.stringify(localStorage.getItem("step_10"));
  const step_10 = JSON.parse(res_data);
  const step10 = JSON.parse(step_10);

  //get addresss
  const res_address = JSON.stringify(localStorage.getItem("address"));
  const address_data = JSON.parse(res_address);
  const address = JSON.parse(address_data);

  //get users info
  const res_userData = JSON.stringify(localStorage.getItem("users_data"));
  const users_data = JSON.parse(res_userData);
  const usersdata = JSON.parse(users_data);

  interface APIRequest {
    address: object;
    phone: any;
    email: any;
    dwelling: Number;
    dwellingExtension: Number;
    liability: Number;
    contentsCoverage: Number;
    otherStructure: Number;
    deductable: Number;
    policyStartDate: any;
    homeFunction: any;
    foundationType: any;
    roofShape: any;
    roofType: any;
    constructionType: any;
    exterior: any;
    roofLastModifiedYear: any;
    electricalSystemLastModifiedYear: any;
    plumbingSysteLastModifiedYear: any;
    ACSystemLastModifiedYear: any;
    people: object;
    environment: string;
    dontQuery: object;
    isCondo: boolean;
    squareFeet: Number;
    yearBuilt: Number;
  }
  // Example main API request

  let mainAPIRequestExample: APIRequest = {
    address: {
      street: address["street"],
      city: address["city"],
      state: address["state"],
      postalCode: address["postalCode"],
    },
    phone: localStorage.getItem("phone"),
    email: localStorage.getItem("email"),

    dwelling: parseInt(step10["dwelling"]),
    dwellingExtension: step10["dwellingExtension"],
    liability: parseInt(step10["liability"]),
    contentsCoverage: step10["contentsCoverage"],
    otherStructure: parseInt(step10["otherStructure"]),
    deductable: parseInt(step10["deductible"]),

    policyStartDate: localStorage.getItem("policyStartDate"),
    homeFunction: localStorage.getItem("homeFunction"),
    foundationType: localStorage.getItem("foundationType"),
    roofShape: localStorage.getItem("roofShape"),
    roofType: localStorage.getItem("roofType"),
    constructionType: localStorage.getItem("constructionType"),
    exterior: localStorage.getItem("exterior"),

    roofLastModifiedYear: localStorage.getItem("roofLastModifiedYear"),
    electricalSystemLastModifiedYear: localStorage.getItem(
      "electricalSystemLastModifiedYear"
    ),
    plumbingSysteLastModifiedYear: localStorage.getItem(
      "plumbingSysteLastModifiedYear"
    ),
    ACSystemLastModifiedYear: localStorage.getItem("ACSystemLastModifiedYear"),
    people: usersdata,
    environment: "staging",
    dontQuery: [],
    isCondo: false,
    squareFeet: 1234,
    yearBuilt: 1975,
  };
  const imgArray = {
    "ethoslife" : ethoslife,
    "branch" : branch,
    "hippo":hippo,
    "nationwide":nationwide,
    "stillwater":stillwater,
    "pg20travlimg":pg20travlimg
  };
  useEffect(() => {
    const callMainAPI = async () => {
      const res = await makeRequestToMainApi(mainAPIRequestExample as any);
      const result = await res.json();

      console.log(result);
      // setResponseData(result);
      setList(result);
      setloaderClass(false);
      // localStorage.setItem("main_api_res", JSON.stringify(result));
    };

    callMainAPI();
  }, []);
  return (
    <div>
      <section className="page20">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mb-5">
              <div className="row mt-4 ">
                <div className="col-md-2 text-start">
                  <div className="head-img">
                    <img src={cactus} alt="" />
                    <img src={home} alt="" />
                  </div>
                </div>
                <div className="col-md-4 mt-1 align-center">
                  <div className="heading">
                    <h3>Your Quotes: </h3>
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div className="sort" id="navbarNavDarkDropdown">
                    <ul className="sort-list">
                      <li className="dropdown">
                        <Link
                          className=" dropdown-toggle"
                          to="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Sort
                        </Link>
                        <ul className="dropdown-menu dropdown-menu-dark">
                          <li>
                            <Link className="dropdown-item" to="#">
                              Highest score
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              Trending
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="#">
                              Data modified
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {loaderClass && (
              <div className="text-center">
                <div className="spinner-border"></div>
                <p>please wait...</p>
              </div>
            )}

            {list.map((val, key) => {
              function nFormatter(num: any, digits: any) {
                var si = [
                  { value: 1, symbol: "" },
                  { value: 1e3, symbol: "K" },
                  { value: 1e6, symbol: "M" },
                  { value: 1e9, symbol: "G" },
                  { value: 1e12, symbol: "T" },
                  { value: 1e15, symbol: "P" },
                  { value: 1e18, symbol: "E" },
                ];
                var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
                var i;
                for (i = si.length - 1; i > 0; i--) {
                  if (Math.abs(num) >= si[i].value) {
                    break;
                  }
                }
                return (
                  (num / si[i].value).toFixed(digits).replace(rx, "$1") +
                  si[i].symbol
                );
              }

              if (val != null) {
                return (
                  <div className="row  nationwide">
                    <div className="col-md-2">
                      <img src={ imgArray[val["companyName"]]} alt="" />
                    </div>
                    <div className="col-md-2 mt-1 text-end">
                      <h5>${nFormatter(val["dwellingCoverage"], 2)}</h5>
                      <p>Dwelling</p>
                    </div>
                    <div className="col-md-1 mt-1 text-center">
                      <h5>${nFormatter(val["deductible"], 2)}</h5>
                      <p id="dwelling-1k">Deductible</p>
                    </div>
                    <div className="col-md-2 mt-1 text-start">
                      <h5>${nFormatter(val["contentsCoverage"], 2)}</h5>
                      <p>contents</p>
                    </div>
                    <div className="col-md-2 mt-4 text-start">
                      <h4 className="price">
                        ${parseFloat(val["price"]).toFixed(2)}{" "}
                      </h4>
                      <span>
                        ${parseFloat(val["monthlyPrice"]).toFixed(2)}/mo
                      </span>
                    </div>
                    <div className="col-md-1 text-end">
                      <a
                        target="_blank"
                        href={val["goButtonLink"]}
                        className="btn"
                      >
                        GO
                      </a>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default StepTwenty;
