import React from "react";
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../images/mapicon.png";

const AutoComplete = () => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const navigate = useNavigate();

  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["geocode"], //establishment,address,geocode
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      localStorage.setItem("lat", place.geometry.location.lat());
      localStorage.setItem("lng", place.geometry.location.lng());

      const address = place['address_components'];
      var postalCode = "";
      var state = "";
      var city = "";
      for(var i=0;i<(address.length);i++)
      {
        if(address[i]['types'][0] == "administrative_area_level_1")
        {
          state = address[i]['short_name'];
        }
        if(address[i]['types'][0] == "administrative_area_level_3")
        {
          city = address[i]['short_name'];
        }
        if(address[i]['types'][0] == "administrative_area_level_2")
        {
          if(city == "")
          {
            city += address[i]['short_name'];
          }else{
            city += "," +address[i]['short_name'];
          }
        }
        if(address[i]['types'][0] == "postal_code")
        {
          postalCode = address[address.length-1]['long_name'];
        }
      }
      const UserAddress = {
        street: place['address_components'][0]['long_name'] + "," + place['address_components'][1]['long_name'],
        city: city,
        state: state,
        postalCode: postalCode,
      };
      console.log(UserAddress)
      localStorage.setItem("address", JSON.stringify(UserAddress));
    });
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    if (!address) {
      setAddressError("Please enter address");
    } else {
      navigate("/stepTwo");
    }
  };

  return (
    <>
      <section className="page1 header">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-8">
              <h1>Home Insurance Bliss </h1>
              <p>
                Enter your address. Get Multiple quotes. Compare and Learn
                coverages Bind Online
              </p>
              <form onSubmit={(e) => e.preventDefault()}>
                <span className="input-group-text">
                  <img src={Icon} alt="" />
                </span>
                <span className="address">address</span>
                <input
                  type="text"
                  className="form-control"
                  id="text"
                  placeholder="what property do you need to insure?"
                  aria-label=""
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required={true}
                  ref={inputRef}
                />
                <div
                  typeof="submit"
                  onClick={submitForm}
                  className="btn bg-dark text-light"
                >
                  run quotes{" "}
                </div>
              </form>
              <span style={{ color: "red" }}>{addressError}</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AutoComplete;
